import React, { forwardRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const RichTextEditor = forwardRef((props, ref) => {
    // Declare modules and formats with const
    const modules = {
        toolbar: [
            [{ font: [] }],
            [{ header: [1, 2, 3, 4, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
                { align: [] },
            ],
            ['link', 'image'],
            ['clean'],
        ],
    }

    const formats = [
        'font',
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'code',
        'list',
        'bullet',
        'indent',
        'align',
        'link',
        'image',
    ]

    return (
        <div className="rich-text-editor">
            <ReactQuill
                ref={ref}
                {...props}
                theme="snow"
                modules={modules} // Use modules directly
                formats={formats} // Use formats directly
            />
        </div>
    )
})

export default RichTextEditor
