import ApiService from './ApiService'

export async function apiSignIn(data) {
    try {
        const response = await ApiService.fetchData({
            url: '/user/login',
            method: 'post',
            data,
        })
        return Promise.resolve(response.data)
    } catch (error) {
        return Promise.reject(error)
    }
}

export async function apiProfile() {
    try {
        const response = await ApiService.fetchData({
            url: '/user/me',
            method: 'GET',
        })
        return Promise.resolve(response.data)
    } catch (error) {
        return Promise.reject(error)
    }
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data,
    })
}

export async function forgotPasswordAPI(data) {
    return ApiService.fetchData({
        url: '/user/forgot-password',
        method: 'PUT',
        data,
    })
}

export async function resetPasswordApi(data) {
    return ApiService.fetchData({
        url: '/user/reset-password',
        method: 'PUT',
        data,
    })
}
